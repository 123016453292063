import AuthHomePage from "views/AuthHomePage/AuthHomePage";

function Home() {
    return (
        <div
            style={{ background: "#FFF", overflow: "hidden", height: "100vh" }}
        >
            <AuthHomePage />
        </div>
    );
}

export default Home;
