import classes from "./Logo.module.css";

function Logo({ ...props }) {
    return (
        <div className={classes.logoFont} {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                id="layer"
                viewBox="0 0 421.73 56.06"
            >
                <defs>
                    <style>{`.cls-1{fill:#244eff;}`}</style>
                </defs>
                <polygon
                    className="cls-1"
                    points="0 31.36 20.12 1.17 49.01 1.43 24.49 23.97 50.79 25.26 4.2 56.06 1.78 56.06 16.32 32.79 0 31.36"
                ></polygon>
                <path
                    className="cls-1"
                    d="m72.74,1.3v53.54h-12.22V1.3h12.22Z"
                ></path>
                <path
                    className="cls-1"
                    d="m105.28,26.73h27.8v21.01l-1.38.99c-2.55,1.99-5.31,3.49-8.29,4.51-2.98,1.02-6.12,1.53-9.43,1.53-3.77,0-7.35-.68-10.73-2.02-3.39-1.35-6.43-3.27-9.13-5.77-2.75-2.6-4.86-5.58-6.34-8.94-1.48-3.36-2.22-6.92-2.22-10.69s.74-7.27,2.22-10.66c1.48-3.39,3.59-6.38,6.34-8.97,2.7-2.5,5.74-4.4,9.13-5.73,3.39-1.32,6.96-1.99,10.73-1.99,3.31,0,6.47.51,9.47,1.53,3,1.02,5.78,2.49,8.33,4.43l1.76,1.37-4.28,12.83-3.44-3.13c-1.58-1.48-3.37-2.61-5.39-3.4-2.01-.79-4.16-1.19-6.45-1.19s-4.46.41-6.49,1.22c-2.04.81-3.85,1.96-5.42,3.44-1.48,1.37-2.61,2.94-3.4,4.7-.79,1.76-1.18,3.6-1.18,5.54s.39,3.78,1.18,5.54c.79,1.76,1.92,3.35,3.4,4.77,1.58,1.48,3.39,2.61,5.42,3.4,2.04.79,4.2,1.18,6.49,1.18,1.17,0,2.3-.09,3.4-.27,1.09-.18,2.15-.5,3.17-.96.41-.2-.04-5.48-.11-5.74-.08-.25-.32-.38-.73-.38h-10.16l-4.28-8.16Z"
                ></path>
                <path
                    className="cls-1"
                    d="m189.97,1.3v53.54h-11.9l-19.17-29.64c-.05-.1-.1-.14-.15-.11-.05.03-.08.09-.08.19v29.56l-12.22-.08V10.95l11.08-9.65,18.94,28.03c.05.1.1.14.15.11.05-.02.08-.09.08-.19V1.3h13.27Z"
                ></path>
                <path
                    className="cls-1"
                    d="m256.59,54.84h-12.98l-1.95-10.8c-.1-.36-.33-.53-.69-.53h-23.07c-.31,0-.53.18-.69.53l-1.95,10.8h-12.98L221.53,1.3h15.81l19.25,53.54Zm-34.45-21.58c-.1.26-.08.48.08.69.15.2.36.3.61.3h13.21c.66,0,.89-.33.69-.99l-6.57-19.55c-.15-.36-.41-.53-.76-.53s-.59.18-.69.53l-6.57,19.55Z"
                ></path>
                <path
                    className="cls-1"
                    d="m280.5,42.62h27.42v12.22h-40.48V10.21l12.22-8.92v40.48c0,.56.28.84.84.84Z"
                ></path>
                <path
                    className="cls-1"
                    d="m333.82,42.62h27.42v12.22h-40.48V10.36l12.22-9.06v40.48c0,.56.28.84.84.84Z"
                ></path>
                <path
                    className="cls-1"
                    d="m421.73,1.45l-24.14,35.98v17.41h-12.15v-17.41L361.31,1.45h14.74l15.43,24.52,15.43-24.52h14.82Z"
                ></path>
            </svg>
        </div>
    );
}

export default Logo;
